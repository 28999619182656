import {ApiDatasource} from '../../adapters/io/datasources/ApiDatasource';
import {unwrap} from '../../adapters/io/network/HttpResponse';
import {formatText} from '../../adapters/io/network/utils';
import {ApiDatasourceSpec} from '../../core/datasource';
import {AuthState} from '@okta/okta-auth-js';
import {buildHeader} from './utils/Utils';
import {DeliveryMethodEligibilityTableStructure} from "../indexes/DeliveryMethodEligibilityIndexes";
import {Order} from "../../../../graphics/tables/operations/sort";

export const searchDeliveryMethodEligibility = <T>(
    authState: AuthState,
    search: DeliveryMethodEligibilityTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DeliveryMethodEligibilityTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches: search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<DeliveryMethodEligibilityTableStructure.ResponseOf<T>, DeliveryMethodEligibilityTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointDeliveryMethodEligibility.endPoint.concat(EndpointDeliveryMethodEligibility.searchDeliveryMethodEligibility), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};
export const getDeliveryMethodEligibilityColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DeliveryMethodEligibilityTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<DeliveryMethodEligibilityTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointDeliveryMethodEligibility.endPoint.concat(EndpointDeliveryMethodEligibility.getDeliveryMethodEligibilityColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const upsertDeliveryMethodEligibility = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointDeliveryMethodEligibility.endPoint.concat(EndpointDeliveryMethodEligibility.upsertDeliveryMethodEligibility), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deleteDeliveryMethodEligibility = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) : Promise<void> => {

    return datasource.remote
        .deleteData(uriFormatter(EndpointDeliveryMethodEligibility.endPoint.concat(EndpointDeliveryMethodEligibility.deleteDeliveryMethodEligibility), {}) + `?id=${id}`, buildHeader(authState), new Map())
        .then(() => {});
};

export const EndpointDeliveryMethodEligibility = {
    endPoint: '/end-point/delivery-method-eligibility',
    getDeliveryMethodEligibilitys: '',
    searchDeliveryMethodEligibility: '/search',
    getDeliveryMethodEligibilityColumnInformation: '/column-information',
    upsertDeliveryMethodEligibility: '/upsert',
    deleteDeliveryMethodEligibility:''
};