import {ApiDatasource} from '../../adapters/io/datasources/ApiDatasource';
import {unwrap} from '../../adapters/io/network/HttpResponse';
import {formatText} from '../../adapters/io/network/utils';
import {ApiDatasourceSpec} from '../../core/datasource';
import {AuthState} from '@okta/okta-auth-js';
import {buildHeader} from './utils/Utils';
import {PreorderConfigTableStructure} from "../indexes/PreorderConfigIndexes";
import {Order} from "../../../../graphics/tables/operations/sort";

export const searchPreorderConfig = <T>(
    authState: AuthState,
    search: PreorderConfigTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<PreorderConfigTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches: search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<PreorderConfigTableStructure.ResponseOf<T>, PreorderConfigTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointPreorderConfig.endPoint.concat(EndpointPreorderConfig.searchPreorderConfig), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};
export const getPreorderConfigColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<PreorderConfigTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<PreorderConfigTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointPreorderConfig.endPoint.concat(EndpointPreorderConfig.getPreorderConfigColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const upsertPreorderConfig = <RecordType>(
    authState: AuthState,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {

    return datasource.remote
        .postData<void, RecordType>(uriFormatter(EndpointPreorderConfig.endPoint.concat(EndpointPreorderConfig.upsertPreorderConfig), {}), record, buildHeader(authState), new Map())
        .then(() => {});
};

export const deletePreorderConfig = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
) : Promise<void> => {

    return datasource.remote
        .deleteData(uriFormatter(EndpointPreorderConfig.endPoint.concat(EndpointPreorderConfig.deletePreorderConfig), {}) + `?id=${id}`, buildHeader(authState), new Map())
        .then(() => {});
};

export const EndpointPreorderConfig = {
    endPoint: '/end-point/preorder-config',
    getPreorderConfigs: '',
    searchPreorderConfig: '/search',
    getPreorderConfigColumnInformation: '/column-information',
    upsertPreorderConfig: '/upsert',
    deletePreorderConfig:''
};