import React, {useContext, useState, useEffect} from 'react';
import {HeadCell} from '../../../../graphics/tables/data/HeadCell';
import {TableRowData} from '../../../../graphics/tables/data/TableRowData';
import {DynamicDatatable, EditOptionType} from '../../../../graphics/tables/DynamicDatatable';
import {Order} from '../../../../graphics/tables/operations/sort';
import {strings} from '../../i18n/strings';
import {Page} from '../../presenters/pages/index';
import {AppContext, AppContextSpec, useAsyncEffect} from '../../App';
import {useOktaAuth} from '../../../../okta-react';
import {DeliveryMethodEligibilityTableStructure} from "../../domain/indexes/DeliveryMethodEligibilityIndexes";
import {
    getDeliveryMethodEligibilityColumnInformation,
    searchDeliveryMethodEligibility,
    deleteDeliveryMethodEligibility
} from "../../domain/repository/DeliveryMethodEligibilityRepository";

import {EditForm as DeliveryMethodEligibilityEditForm} from "../../domain/forms/DeliveryMethodEligibilityEditForm";
import {EditFormModalOptions} from "../../domain/forms/TransporterDaysOffWeekEditForm";

// Interfaces & types
export type Record = DeliveryMethodEligibilityTableStructure.RecordOf<DeliveryMethodEligibilityTableStructure.DeliveryMethodEligibility>;

export interface RecordRepresentation extends Record, TableRowData {
}

export interface RecordData {
    record: RecordRepresentation;
    type: EditMode;
}

const tableFields: Array<string> = [
    'id',
    'channel',
    'country',
    'shipmentMethod'
];

// Datatable configuration
export enum EditMode {
    Add,
    Edit,
    Duplicate
}

// Wordings
const wordingDeliveryMethodEligibilityPage = strings.page.deliveryMethodEligibility;

export const DeliveryMethodEligibilityPage = () => {

    const DEFAULT_PAGINATION_LIMIT: number = (process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) ? parseInt(process.env.REACT_APP_DEFAULT_PAGINATION_LIMIT) : 20;
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<RecordRepresentation>[]>([]);
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<DeliveryMethodEligibilityTableStructure.ResponseOf<DeliveryMethodEligibilityTableStructure.DeliveryMethodEligibility>>();
    const [tableDataDefinition, setTableDataDefinition] = useState<DeliveryMethodEligibilityTableStructure.ResponseOfColumn<DeliveryMethodEligibilityTableStructure.DeliveryMethodEligibility>>();
    const {authState} = useOktaAuth();
    const appContext = useContext<AppContextSpec>(AppContext);

    const wordingsDatatable = strings.datatable;
    const [limit, setLimit] = useState(DEFAULT_PAGINATION_LIMIT);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>(tableFields[2]);
    const [recordToEdit, setRecordToEdit] = useState<RecordData>();
    const [editModalOpened, setEditModalOpened] = useState(false);
    const [loading, setLoading] = useState(false);

    const headCells: HeadCell<RecordRepresentation>[] = tableFields.map((field) => ({
        id: field,
        label: field,
        displayName: field,
        type: "",
        required: true,
        information: "",
        position: 1,
        visible: true,
        editable: true,
        massEditable: false,
        reference: "",
        referenceColumnLabel: "",
        disablePadding: false,
        searchValue: "",
    }));

    //Get HeadCells to display tab names
    useEffect(() => {
        (async () => {
            try {
                const headCellConfigValues: DeliveryMethodEligibilityTableStructure.ResponseOfColumn<DeliveryMethodEligibilityTableStructure.DeliveryMethodEligibility> = await getDeliveryMethodEligibilityColumnInformation<DeliveryMethodEligibilityTableStructure.DeliveryMethodEligibility>(authState!);
                headCells.forEach((cell) => {
                    const config = headCellConfigValues[cell.id];
                    cell.id = config.name;
                    cell.label = config.name;
                    cell.type = config.type;
                    cell.required = config.required;
                    cell.information = config.information;
                    cell.position = config.position;
                    cell.visible = config.visible;
                    cell.editable = config.editable;
                    cell.massEditable = config.massEditable;
                    cell.displayName = (config.displayName.length > 0) ? String(config.displayName) : String(config.name);
                });
                setHeadCellsConfig(headCells);
                setTableDataDefinition(headCellConfigValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            }
        })();
        // eslint-disable-next-line
    }, []);

    const getAndRefreshRecords = (newList ?: HeadCell<RecordRepresentation>[]) => {

        let headCellsConfigForGetAndRefresh = (newList) ? newList : headCellsConfig;

        let formattedSearchValues: DeliveryMethodEligibilityTableStructure.FormattedSearchValue[] = headCellsConfigForGetAndRefresh
            .filter(cell => cell.label && cell.searchValue)
            .map(cell => {
                return {
                    key: cell.label,
                    value: cell.searchValue
                };
            });

        const updateState = (deliveryMethodEligibilityValues: DeliveryMethodEligibilityTableStructure.ResponseOf<DeliveryMethodEligibilityTableStructure.DeliveryMethodEligibility>) => {
            setTableData(deliveryMethodEligibilityValues);
            setRowData(deliveryMethodEligibilityValues.values);

            if (page !== deliveryMethodEligibilityValues.pagination.page) {
                setPage(deliveryMethodEligibilityValues.pagination.page);
            }
        }

        (async () => {
            try {
                setLoading(true);
                const deliveryMethodEligibilityValues: DeliveryMethodEligibilityTableStructure.ResponseOf<DeliveryMethodEligibilityTableStructure.DeliveryMethodEligibility> =
                    await searchDeliveryMethodEligibility<Record>(authState!, formattedSearchValues, page, limit, order, orderBy);

                if (page !== deliveryMethodEligibilityValues.pagination.page) {
                    setPage(deliveryMethodEligibilityValues.pagination.page);
                }
                updateState(deliveryMethodEligibilityValues);
            } catch (error: any) {
                console.log("ERROR: ", error);
            } finally {
                setLoading(false);
            }
        })();
    }

    useAsyncEffect(async () => {
            await getAndRefreshRecords();
        }, [page, limit, order, orderBy],
    );

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = (newList: HeadCell<RecordRepresentation>[]) => {
        setPage(1);
        headCellsConfig.forEach((cell: HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });

        getAndRefreshRecords(headCellsConfig);
    };

    const fillAndOpenEditForm = (record?: RecordData) => {
        setRecordToEdit(record);
        setEditModalOpened(true);
    };

    const computeAddOption = () => {
        return {
            buttonLabel: wordingDeliveryMethodEligibilityPage.datatable.action.add.buttonLabel,
            onClick: fillAndOpenEditForm,
        }
    };

    /**
     * Computed options
     */
    const handleEditOptionClick = (option: EditOptionType, record: RecordRepresentation) => {
        switch (option) {
            case EditOptionType.Edit:
                fillAndOpenEditForm({record: record, type: EditMode.Edit});
                break;
            case EditOptionType.Duplicate:
                fillAndOpenEditForm({ record: record, type: EditMode.Duplicate });
                break;
            case EditOptionType.Delete:
                appContext.prompt(
                    wordingDeliveryMethodEligibilityPage.datatable.action.remove.confirmationPromptMessage,
                    async (isConfirmed) => {
                        if (isConfirmed) {
                            try {
                                appContext.displayLoader(true);
                                await deleteDeliveryMethodEligibility(authState!, record.id);
                                getAndRefreshRecords();
                                appContext.notify({ message: wordingDeliveryMethodEligibilityPage.datatable.action.remove.confirmedFeedbackMessage });
                            } catch (error: any) {
                                let errorMessage: string = error.message;
                                if (!errorMessage.includes('permission')){
                                    errorMessage = "An error occurred while deleting the record";
                                }
                                appContext.notifyError(errorMessage);
                            } finally {
                                appContext.displayLoader(false);
                            }
                        }
                    },
                    { confirmButtonColor: 'secondary' }
                );
                break;

        }
    };

    const computeEditOptions = () => {
        return [
            {
                id: EditOptionType.Edit,
                name: wordingDeliveryMethodEligibilityPage.datatable.action.edit.menuItemLabel,
                onClick: handleEditOptionClick
            },
            {
                id: EditOptionType.Duplicate,
                name: wordingDeliveryMethodEligibilityPage.datatable.action.duplicate.menuItemLabel,
                onClick: handleEditOptionClick,
            },
            {
                id: EditOptionType.Delete,
                name: wordingDeliveryMethodEligibilityPage.datatable.action.remove.menuItemLabel,
                onClick: handleEditOptionClick
            },
        ];
    };
    /**
     * Modal row add
     */
    const handleEditModalClose = () => {
        setEditModalOpened(false);
        setRecordToEdit(undefined);
    }

    const handleEditModalOk = (shouldReset: boolean) => {
        const feedbackWordings = recordToEdit ? (recordToEdit.type === EditMode.Edit ? wordingDeliveryMethodEligibilityPage.datatable.action.edit : wordingDeliveryMethodEligibilityPage.datatable.action.duplicate) : wordingDeliveryMethodEligibilityPage.datatable.action.add;
        !shouldReset && setEditModalOpened(false);

        (async () => {
            try {
                getAndRefreshRecords();
                appContext.notifySuccess(feedbackWordings.confirmationFeedbackMessage);
            } catch (error: any) {
                appContext.notifyError(error);
            }
        })();
    };


    const computeEditModalOptions = (): EditFormModalOptions => {

        const modalWordings = recordToEdit
            ? (recordToEdit?.type === EditMode.Duplicate ? wordingDeliveryMethodEligibilityPage.datatable.action.duplicate.modal : wordingDeliveryMethodEligibilityPage.datatable.action.edit.modal)
            : wordingDeliveryMethodEligibilityPage.datatable.action.add.modal;

        return {
            title: modalWordings.title,
            applyLabel: modalWordings.applyButtonLabel,
            cancelLabel: modalWordings.cancelButtonLabel,
            applyAndResetLabel: recordToEdit ? (recordToEdit.type === EditMode.Duplicate ? wordingDeliveryMethodEligibilityPage.datatable.action.duplicate.modal.applyAndResetLabel : undefined) : wordingDeliveryMethodEligibilityPage.datatable.action.add.modal.applyAndResetLabel,
            open: editModalOpened,
            onClose: handleEditModalClose,
            onSuccess: async (shouldReset: boolean) => {
                await handleEditModalOk(shouldReset);
            }
        };
    };


    return (
        <Page
            title={wordingDeliveryMethodEligibilityPage.title}
            descriptionLine1={wordingDeliveryMethodEligibilityPage.description.line1}
            descriptionLine2={<span style={{ fontWeight: 'bold' }}>{wordingDeliveryMethodEligibilityPage.description.line2}</span>}
        >
            {(
                <>
                    <DynamicDatatable
                        isLoading={loading}
                        stickyHeader={true}
                        headCells={headCellsConfig}
                        data={rowData}
                        initialOrderBy={tableFields[0]}
                        showPadding={false}
                        editOptions={computeEditOptions()}
                        allowAdding={computeAddOption()}
                        paginationOptions={(() => ({
                            page,
                            limit,
                            order,
                            orderBy,
                            total: tableData?.pagination?.total || 0,
                            rowsPerPageValues: [
                                {nb: DEFAULT_PAGINATION_LIMIT / 2, isDefault: true},
                                {nb: DEFAULT_PAGINATION_LIMIT, isDefault: true},
                                {nb: DEFAULT_PAGINATION_LIMIT * 2, isDefault: false},
                                {nb: DEFAULT_PAGINATION_LIMIT * 4, isDefault: false},
                            ],
                            meetNbRowsPerPage: false,
                            rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                            onPaginationChange: handlePaginationChange,
                            onHandleSortChange: handleSortChange,
                            onMultiCellsSearch: handleMultiCellsSearch
                        }))()}
                    />
                </>
            )}
            {tableDataDefinition && (
                <DeliveryMethodEligibilityEditForm
                    key={recordToEdit ? recordToEdit.type : EditMode.Add}
                    record={recordToEdit ? recordToEdit : undefined}
                    constraints={tableDataDefinition}
                    modalOptions={computeEditModalOptions()}
                />
            )}
        </Page>
    );
};
